import React from "react"
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, DotGroup, ButtonPlay } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { useMediaQuery } from 'react-responsive';

import Civic from "../../content/pages/home/backgrounds/civic-action.svg";
import Learn from "../../content/pages/home/backgrounds/learn-reflect.svg";
import PhoneWall from "../../content/pages/home/backgrounds/wall-final.svg";
import ShuttingDown from "../../content/pages/home/backgrounds/shutting-down.svg";
import Control from "../../content/pages/home/backgrounds/control.svg";
import WordWithYou from "../../content/pages/home/backgrounds/word-with-you.svg";
import Play from "../../content/assets/play-button.svg";
import Pause from "../../content/assets/pause-button.svg";

const Carousel = () => {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 600px)' });
    const isLarger = useMediaQuery({ query: '(max-width: 650px)' });

    return (
        <CarouselProvider
            naturalSlideWidth={100}
            naturalSlideHeight={70}
            naturalSlideHeight=
            { isTabletOrMobile
                ? 70
                : [
                    (isLarger
                        ? 70
                        : 65
                    )
                ]
            }
            naturalSlideHeight={isTabletOrMobile ? 85 : 72}
            isPlaying={true}
            interval={5000}
            totalSlides={5}
            infinite
        >
            <div className="slider-container">
                <Slider>
                    <Slide index={0}>
                        <div class="slide-container"> 
                            <div className="svg-hero shrink-img" style={{ width: "90%" }}>
                                <PhoneWall/>
                            </div>
                            <div className="">
                                <h2>Are we building connections or walls?</h2> 
                            </div>
                        </div>
                    </Slide>
                    <Slide index={1}>
                        <div class="slide-container">
                            <div className="svg-hero shrink-img" style={{ width: "75%" }}>
                                <Learn />
                            </div>
                            <div className="carousel-text">
                                <h2> Is together...<br /> really together? </h2>
                            </div>
                        </div>
                    </Slide>
                    <Slide index={2}>
                        <div class="slide-container"> 
                            <div className="svg-hero shrink-img" style={{ width: "55%" }}>
                                <Control/>
                            </div> 
                            <h2>Who's in charge?</h2> 
                        </div>
                    </Slide>
                    <Slide index={3}>
                        <div class="slide-container"> 
                            <div className="svg-hero shrink-img" style={{ width: "53%" }}>
                                <ShuttingDown/>
                            </div> 
                            <h2>Is tech shutting down the conversation?</h2> 
                        </div>
                    </Slide>
                    <Slide index={4}>
                        <div class="slide-container"> 
                            <div className="svg-hero shrink-img" style={{width: "80%"}}>
                                <WordWithYou/>
                            </div>
                            <div className="carousel-text">
                                <h2> Stop scrolling... your cat would like to have a word with you. </h2> 
                            </div>
                        </div>
                    </Slide>
                </Slider>
                <ButtonBack className="button-back">
                        <svg width="24px" height="24px" viewBox="0 0 24 24" id="736d2dce-205b-4e1d-a8de-21d9524ba21c" data-name="Livello 1" xmlns="http://www.w3.org/2000/svg"><title>prime</title><g id="6037caba-d996-4e01-a295-2e233316e41b" data-name="Arrow Left"><path d="M16.23,23.58l2.24-2a0.5,0.5,0,0,0,0-.71L10.57,12,18.5,3.13a0.5,0.5,0,0,0,0-.71l-2.24-2a0.5,0.5,0,0,0-.71,0L5.2,12,15.52,23.54A0.5,0.5,0,0,0,16.23,23.58Z" /></g></svg>
                </ButtonBack>
                <ButtonNext className="button-next">
                        <svg width="24px" height="24px" viewBox="0 0 24 24" id="fa26cff6-f100-48f4-a81c-6e0cb8b1ea4a" data-name="Livello 1" xmlns="http://www.w3.org/2000/svg"><title>prime</title><g id="880b5b52-5578-436c-a64a-10a281e56ea6" data-name="Arrow Left"><path d="M7.77,23.58l-2.24-2a0.5,0.5,0,0,1,0-.71L13.43,12,5.5,3.13a0.5,0.5,0,0,1,0-.71l2.24-2a0.5,0.5,0,0,1,.71,0L18.8,12,8.48,23.54A0.5,0.5,0,0,1,7.77,23.58Z" /></g></svg>
                </ButtonNext>
            </div>
            <div className="dot-group-container">
                <DotGroup className="dot-group" />
                <ButtonPlay className="play-pause" childrenPlaying={<Pause />} childrenPaused={<Play />} />
            </div>
        </CarouselProvider>
    )
}

export default Carousel
