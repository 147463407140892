import React from "react"
import { graphql } from "gatsby"
import Civic from "../../content/pages/home/backgrounds/civic-action.svg";
import Learn from "../../content/pages/home/backgrounds/learn-reflect.svg";
import Questioning from "../../content/pages/home/backgrounds/questioning-tech2.svg";
import Carousel from "../components/carousel";

import BackgroundSlider from 'gatsby-image-background-slider';
import Layout from "../components/layout"
import SEO from "../components/seo"

import "../styles/style.css"

class HomePage extends React.Component {
    render() {
        const { data } = this.props;
        const pageContent = data.markdownRemark;
        const siteTitle = data.site.siteMetadata.title
        return (
            <Layout title={siteTitle}>
                {/* {pageContent.frontmatter.hero &&
                    <img
                        alt={pageContent.frontmatter.hero.childImageSharp.fluid.originalName}
                        src={pageContent.frontmatter.hero.childImageSharp.fluid.src}
                    />
                } */}
                <div className="home-header" >
                    <h1 className="page-title underline">{siteTitle}</h1>
                    <p> Critically examining technology and the role that it plays in our lives.</p>
                </div>
                <Carousel/>
                <div className="content-full"> 
                <SEO title="Home" />
                {/* 
                <div className="svg-hero">
                    <PhoneWall/>
                </div> 
                */}
                    
                    <div className="home-subtitle">
                        <h2 >{pageContent.frontmatter.title}</h2>
                    </div>
                    <div className="section approach">
                        <h1 className="page-title underline">Approach</h1>
                        <div className="grid-about">
                            <span className="num-civic">1</span>
                            <div className="svg-hero pic-civic">
                                <Civic/>
                            </div>
                            <p className="text-civic">Taking on Tech provides a space to critically examine technology and the role that it plays in our lives. We want to empower people to make more informed decisions about how they integrate technology into their lives.</p>
                            <span className="num-learn">2</span>
                            <div className="svg-hero pic-learn">
                                <Questioning/>
                            </div>
                            <p className="text-learn">Tangible change cannot occur from individuals — it is driven by organizing people together. Taking on Tech promotes civic engagement within and outside of the internet in order to influence policy.</p>
                        </div> 
                    </div>
                    
                 {/*   <section dangerouslySetInnerHTML={{ __html: pageContent.html }} /> */}
      
                </div>
               
            </Layout>
        )
    }
}

export default HomePage

export const pageQuery = graphql`
    query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
        markdownRemark(fields: {slug: {eq: "/home/"}}) {
            id
            frontmatter {
                title
               
            }
            html
        }
    }
`

